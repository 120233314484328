/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Raleway:wght@400;500;700&display=swap");
@import "./variables";
@import "../node_modules/bootstrap/scss/bootstrap";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  color: $primary;
}
html {
  font-family: "Lato", sans-serif;
  scroll-behavior: smooth;
  font-size: $h6-size;
}
body {
  min-height: 100vh;
  &:before {
    transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      //background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) ease-in,
      opacity 0.55s ease;
    content: "";
    display: block;
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $darker-green;
    opacity: 0;
    z-index: -1;
  }
  &.branding-open {
    overflow: hidden;
    &:before {
      opacity: 0.2;
      pointer-events: none;
      z-index: 1;
    }
  }
}

h1,
.h1 {
  font-size: $h1-size;
  line-height: $h1-line;
}
h2,
.h2 {
  font-size: $h2-size;
  line-height: $h2-line;
}
h3,
.h3 {
  font-size: $h3-size;
  line-height: $h3-line;
}
h4,
.h4 {
  font-size: $h4-size;
  line-height: $h4-line;
}
h5,
.h5 {
  font-size: $h5-size;
  line-height: $h5-line;
}
h6,
.h6 {
  font-size: $h6-size;
  line-height: $h6-line;
}
.pt-38vh {
  padding-top: 38vh;
}
.pt-24vh {
  padding-top: 24vh;
}
.pt-14vh {
  padding-top: 14vh !important;
}
pre {
  background-color: var(--secondary);
  color: var(--white);
}
.logo {
  width: 209px;
  height: 69px;
  transition: all 0.25s;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  &:hover {
    border-bottom: 0 !important;
  }
  svg {
    height: 69px;
    width: auto;
    display: block;
    margin-top: -5px;
    margin-left: -1px;
    transition: all;
    path {
      transition: all;
    }
  }
}

.menu-black {
  .navbar-branding {
    background-color: $black-green;
    .menu-toggle {
      margin-top: 0 !important;
    }
  }

  .logo {
    height: 52px;
    svg {
      height: 52px;
    }
  }
}
.menu-white {
  .navbar-branding {
    transition: all 0.25s;
    background-color: $transparent-black;
  }

  .mega-menu .menu-toggle span {
    background-color: $white !important;
  }
  .logo {
    svg {
      path {
        fill: $white;
      }
    }
  }
}
.menu-none {
  .navbar {
    display: none !important;
  }
}
.bg-wrapper {
  min-height: 100vh;
  overflow-x: hidden;
  @media (min-width: map-get($grid-breakpoints, md)) {
    overflow: hidden;
  }

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transition: opacity 0.25s ease-in;
    background-size: 100% auto;
    background-size: cover;
    background-repeat: no-repeat;

    background-image: url(/assets/transparent.png);

    @media (orientation: landscape) {
      background-position-y: center;
      background-position-x: center;
      background-size: contain;
    }

    @media (orientation: portrait) {
      background-position-y: bottom;
    }
  }

  .donut-cop-content {
    display: none;
  }
  .donut-cop-content,
  .naviy-content,
  .team-john-content,
  .team-shane-content,
  .news-content {
    display: none;
  }

  &.home {
    &:before {
    }
  }
}

.section-header * {
  line-height: 1;
  margin-bottom: $spacing-1;
}
.rich-content {
  p {
    @extend .text-white;
  }
  h2 {
    @extend .h4;
    @extend .text-uppercase;
    @extend .font-weight-bold;
  }
  h3 {
    @extend .h5;
    @extend .text-uppercase;
    @extend .font-weight-bold;
  }

  img + img {
    display: none;
  }
  p:after {
    display: block;
    clear: both;
    content: "";
  }
  .alignleft {
    float: left;
  }
  img.alignleft {
    padding: $spacing-4 $spacing-5 $spacing-5 0;
  }
  .alignright {
    float: right;
  }
  img.alignright {
    padding: $spacing-4 0 $spacing-5 $spacing-5;
  }
  .wp-caption {
    padding: $spacing-2 $spacing-5;
    width: 38%;
    img {
      width: 100%;
      height: auto;
      padding: 0 0 $spacing-3 0;
    }
    p {
      @extend .small;
    }
  }
}
