$primary: #57cccb;
$blue: #57cccb;
$font-family-sans-serif: "Lato", sans-serif;
$headings-font-family: "Raleway", sans-serif;

$white-green: #e8ffff;
$light-green: #57cccb;
$dark-green: #005d5d;
$darker-green: #061f1f;
$black-green: #021111;
$transparent-white: rgba(255, 255, 255, 0);
$transparent-black: rgba(0, 0, 0, 0);

$mobile-height: 667px;
$mobile-width: 345px;

$h1-size: 55px;
$h1-line: 82px;
$h2-size: 43px;
$h2-line: 65px;
$h3-size: 34px;
$h3-line: 52px;
$h4-size: 27px;
$h4-line: 42px;
$h5-size: 21px;
$h5-line: 33px;
$h6-size: 17px;
$h6-line: 27px;

$h1-font-size: $h1-size;
$h2-font-size: $h2-size;
$h3-font-size: $h3-size;
$h4-font-size: $h4-size;
$h5-font-size: $h5-size;
$h6-font-size: $h6-size;

$white: $white-green;
$spacing-1: 4px;
$spacing-2: 7px;
$spacing-3: 12px;
$spacing-4: 20px;
$spacing-5: 33px;
$spacing-6: 53px;

$body-bg: $darker-green;
$body-color: $primary;
$link-color: $white-green;
$black: $black-green;

$line-height-base: 1.62;
$secondary: $dark-green;
